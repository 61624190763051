var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "change": function (payload) {
        return _vm.redirect(payload);
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }