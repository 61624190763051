<template>
    <kcp-cert ref="kcpCert" @change="payload => redirect(payload)"></kcp-cert>
</template>

<script>
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
export default {
    components: {
        KcpCert
    },
    mounted(){
        this.$refs.kcpCert.open();
    },
    methods: {
        redirect(payload){
            window.location.href=`${this.redirectUrl}?_certification=${payload._certification}`;
        }
    },
    computed: {
        redirectUrl(){
            return this.$route.query.redirectUrl;
        }
    }
}
</script>